#FooterContainer {
  width: 100%;
  height: 2vh;
  background-color: white;
  min-width: 50px;
  padding: 0;
  padding-right: 5px;
  /*float: left;*/
  left: 0;
  /* line-height: 20px; */
  font: 10px / normal Arial, Helvetica, San-Serif;
  position: relative;

  /*margin-top: -20px;*/
  z-index: 1001;
}

#FooterContainer span {
  float: right;
}
