body {
  margin: 0;
  padding: 0;
  font-family: inherit;
}

.btnimage {
  margin-left: 1px;
  height: 33.2px;
  width: 33.2px;
  padding: 2px;
  background-color: white;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  cursor: pointer;
}

.btnimage.enabled {
  background-color: gray;
  cursor: default;
}

div.upload {
  margin: 0 auto;
  display: block;
  text-align: center;
  width: 130px;
  z-index: 2000;
  position: fixed;
  margin-top: 94px;
  margin-left: 40px;
}

/* div.center {
    margin: 0 auto;
    display: block;
    text-align: center;
    width: 360px;
    z-index: 800;
    position: fixed;
    margin-top: 94px;
    margin-left: 40px;
  }
  
  div.center h4 {
    text-align: left;
  } */

@media only screen and (max-width: 600px) {
  html,
  body {
    overflow: hidden;
    overflow: hidden;
  }

  body {
    position: relative;
    height: 100vh;
  }

  div.tool-container {
    padding-top: 60px;
    width: 100%;
    height: 20vh;
  }

  div.opacity-container {
    width: 80%;
    display: block;
    margin: 0 auto;
  }

  div.opacity-container h4 {
    display: none;
  }

  p {
    display: none;
  }

  .btnimage {
    width: 13%;
    text-align: center;
    margin: 2px;
  }

  .btnimage span.tool-text {
    display: none;
  }
}
